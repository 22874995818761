var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "edit-cell",
      on: { focusout: _vm.onFocusOut, keydown: _vm.onKeyDown },
    },
    [
      _c("multiselect", {
        ref: "multiselect",
        staticClass:
          "custom-dropdown-options aggrid-cell-editor enable-option-icon",
        attrs: {
          "max-height": 300,
          options: _vm.sanitizedOptions.map((i) => i.value),
          "custom-label": _vm.getOptionLabel,
          placeholder: "",
          searchable: false,
          "allow-empty": false,
          showLabels: false,
        },
        on: { input: _vm.onChange },
        scopedSlots: _vm._u([
          {
            key: "option",
            fn: function (props) {
              return [
                _vm.valueStr == props.option
                  ? _c("font-awesome-icon", {
                      staticClass: "selected-option-icon",
                      attrs: { icon: ["far", "check"] },
                    })
                  : _vm._e(),
                _c("span", { staticClass: "option__title" }, [
                  _vm._v(_vm._s(_vm.getOptionLabel(props.option))),
                ]),
              ]
            },
          },
        ]),
        model: {
          value: _vm.valueStr,
          callback: function ($$v) {
            _vm.valueStr = $$v
          },
          expression: "valueStr",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }