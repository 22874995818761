var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { height: "100%", width: "100%" } },
    [
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.isNew
              ? _vm.$t("task.group.title_new_task")
              : _vm.$t("task.group.title_edit_task"),
            "content-class": "shadow",
            "no-close-on-backdrop": "",
          },
          on: { ok: _vm.ok, hidden: _vm.closeModal },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                var ok = ref.ok
                var cancel = ref.cancel
                return [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        disabled:
                          _vm.tName === null ||
                          _vm.tName.trim().length == 0 ||
                          _vm.isTaskNameDuplicated,
                        size: "sm",
                        variant: "success",
                      },
                      on: {
                        click: function ($event) {
                          return ok()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.confirm")))]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.tShow,
            callback: function ($$v) {
              _vm.tShow = $$v
            },
            expression: "tShow",
          },
        },
        [
          _c(
            "div",
            { staticClass: "container pl-0 pr-0" },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    {
                      staticStyle: {
                        width: "87%",
                        flex: "0 0 87%",
                        "max-width": "87%",
                        "padding-right": "0",
                      },
                      attrs: { cols: "10" },
                    },
                    [
                      _c(
                        "b-form-group",
                        { attrs: { label: _vm.$t("task.field.name") } },
                        [
                          _c(
                            "b-input-group",
                            [
                              _c("b-form-input", {
                                ref: "newTaskName",
                                class: {
                                  "is-invalid": _vm.isTaskNameDuplicated,
                                },
                                attrs: { maxlength: "25" },
                                on: { keyup: _vm.newTasknamekeyup },
                                model: {
                                  value: _vm.tName,
                                  callback: function ($$v) {
                                    _vm.tName = $$v
                                  },
                                  expression: "tName",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-form-invalid-feedback",
                            {
                              staticClass: "alert-danger form-field-alert",
                              class: { "d-block": _vm.isTaskNameDuplicated },
                              staticStyle: { "margin-top": "1px" },
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: ["far", "circle-exclamation"] },
                              }),
                              _vm._v(
                                "  " +
                                  _vm._s(
                                    _vm.$t(
                                      "task.error.task_name_already_exists"
                                    )
                                  ) +
                                  " "
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.isColorVisible
                    ? _c(
                        "b-col",
                        {
                          staticStyle: {
                            width: "13%",
                            flex: "0 0 13%",
                            "max-width": "13%",
                          },
                          attrs: { cols: "1" },
                        },
                        [
                          _c(
                            "div",
                            [
                              _c("Color", {
                                attrs: {
                                  update: _vm.updatedColor,
                                  styleClass: "",
                                  labelStyleClass: "color-label",
                                  disabled: _vm.isColorReadOnly,
                                },
                                model: {
                                  value: _vm.tColor,
                                  callback: function ($$v) {
                                    _vm.tColor = $$v
                                  },
                                  expression: "tColor",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.isSkillVisible
                    ? _c(
                        "b-col",
                        { attrs: { cols: "12" } },
                        [
                          _c(
                            "b-form-group",
                            [
                              _c("label", { staticClass: "mr-1" }, [
                                _vm._v(_vm._s(_vm.$t("task.field.skills"))),
                              ]),
                              !_vm.isSkillReadOnly
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "btn-action",
                                      attrs: { id: "SKILL_ADD" },
                                      on: { click: _vm.skillSelectorToggle },
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        attrs: { icon: ["far", "plus"] },
                                      }),
                                      _c("b-popover", {
                                        attrs: {
                                          target: "SKILL_ADD",
                                          placement: "top",
                                          triggers: "hover",
                                          content: _vm.$t(
                                            "task.button.skill_add"
                                          ),
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c("BadgeGroup", {
                                attrs: { readOnly: _vm.isSkillReadOnly },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var item = ref.item
                                        var index = ref.index
                                        return [
                                          _c("Badge", {
                                            key: index,
                                            attrs: {
                                              text: item.level
                                                ? item.name +
                                                  " (" +
                                                  item.level +
                                                  ")"
                                                : item.name,
                                              variant: "primary",
                                              pillable: !!item.pillable,
                                              readOnly: _vm.isSkillReadOnly,
                                            },
                                            on: {
                                              badgeRemove: function ($event) {
                                                return _vm.skillBadgeRemove(
                                                  index
                                                )
                                              },
                                              badgeClick: function ($event) {
                                                return _vm.skillBadgeClick(
                                                  item.uuId
                                                )
                                              },
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3152026025
                                ),
                                model: {
                                  value: _vm.tSkills,
                                  callback: function ($$v) {
                                    _vm.tSkills = $$v
                                  },
                                  expression: "tSkills",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isStaffVisible
                    ? _c(
                        "b-col",
                        { attrs: { cols: "12" } },
                        [
                          _c(
                            "b-form-group",
                            [
                              _c("label", { staticClass: "mr-1" }, [
                                _vm._v(_vm._s(_vm.$t("task.field.staffs"))),
                              ]),
                              !_vm.isStaffReadOnly
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "btn-action",
                                      attrs: { id: "STAFF_ADD" },
                                      on: { click: _vm.staffSelectorToggle },
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        attrs: { icon: ["far", "plus"] },
                                      }),
                                      _c("b-popover", {
                                        attrs: {
                                          target: "STAFF_ADD",
                                          placement: "top",
                                          triggers: "hover",
                                          content: _vm.$t(
                                            "task.button.staff_add"
                                          ),
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c("BadgeGroup", {
                                attrs: { readOnly: _vm.isStaffReadOnly },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var item = ref.item
                                        var index = ref.index
                                        return [
                                          _c("Badge", {
                                            key: index,
                                            attrs: {
                                              text:
                                                item.name +
                                                " (" +
                                                Math.trunc(
                                                  item.utilization * 100
                                                ) +
                                                "%)",
                                              variant: item.genericStaff
                                                ? "info"
                                                : "primary",
                                              pillable: !!item.pillable,
                                              readOnly: _vm.isStaffReadOnly,
                                            },
                                            on: {
                                              badgeRemove: function ($event) {
                                                return _vm.staffBadgeRemove(
                                                  index
                                                )
                                              },
                                              badgeClick: function ($event) {
                                                return _vm.staffBadgeClick(
                                                  item.uuId
                                                )
                                              },
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3266930094
                                ),
                                model: {
                                  value: _vm.tStaff,
                                  callback: function ($$v) {
                                    _vm.tStaff = $$v
                                  },
                                  expression: "tStaff",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isResourceVisible
                    ? _c(
                        "b-col",
                        { attrs: { cols: "12" } },
                        [
                          _c(
                            "b-form-group",
                            [
                              _c("label", { staticClass: "mr-1" }, [
                                _vm._v(_vm._s(_vm.$t("task.field.resources"))),
                              ]),
                              !_vm.isResourceReadOnly
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "btn-action",
                                      attrs: { id: "RESOURCE_ADD" },
                                      on: { click: _vm.resourceSelectorToggle },
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        attrs: { icon: ["far", "plus"] },
                                      }),
                                      _c("b-popover", {
                                        attrs: {
                                          target: "RESOURCE_ADD",
                                          placement: "top",
                                          triggers: "hover",
                                          content: _vm.$t(
                                            "task.button.resource_add"
                                          ),
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c("BadgeGroup", {
                                attrs: { readOnly: _vm.isResourceReadOnly },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var item = ref.item
                                        var index = ref.index
                                        return [
                                          _c("Badge", {
                                            key: index,
                                            attrs: {
                                              text:
                                                item.name +
                                                " (" +
                                                item.quantity +
                                                ")",
                                              variant: "primary",
                                              pillable: !!item.pillable,
                                              readOnly: _vm.isResourceReadOnly,
                                            },
                                            on: {
                                              badgeRemove: function ($event) {
                                                return _vm.resourceBadgeRemove(
                                                  index
                                                )
                                              },
                                              badgeClick: function ($event) {
                                                return _vm.resourceBadgeClick(
                                                  item.uuId
                                                )
                                              },
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1417409387
                                ),
                                model: {
                                  value: _vm.tResources,
                                  callback: function ($$v) {
                                    _vm.tResources = $$v
                                  },
                                  expression: "tResources",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isRebateVisible
                    ? _c(
                        "b-col",
                        { attrs: { cols: "12" } },
                        [
                          _c(
                            "b-form-group",
                            [
                              _c("label", { staticClass: "mr-1" }, [
                                _vm._v(_vm._s(_vm.$t("task.field.rebates"))),
                              ]),
                              !_vm.isRebateReadOnly
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "btn-action",
                                      attrs: { id: "REBATE_ADD" },
                                      on: { click: _vm.rebateSelectorToggle },
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        attrs: { icon: ["far", "plus"] },
                                      }),
                                      _c("b-popover", {
                                        attrs: {
                                          target: "REBATE_ADD",
                                          placement: "top",
                                          triggers: "hover",
                                          content: _vm.$t(
                                            "task.button.rebate_add"
                                          ),
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "b-form-text",
                                { staticClass: "rebate-total mr-1" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("task.field.total_rebate", [
                                          _vm.totalRebate,
                                        ])
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _c("BadgeGroup", {
                                attrs: { readOnly: _vm.isRebateReadOnly },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var item = ref.item
                                        var index = ref.index
                                        return [
                                          _c("Badge", {
                                            key: index,
                                            attrs: {
                                              text:
                                                item.name +
                                                " (" +
                                                _vm.formatRebate(item.rebate) +
                                                "%)",
                                              variant: "primary",
                                              pillable: !!item.pillable,
                                              readOnly: _vm.isRebateReadOnly,
                                            },
                                            on: {
                                              badgeRemove: function ($event) {
                                                return _vm.rebateBadgeRemove(
                                                  index
                                                )
                                              },
                                              badgeClick: function ($event) {
                                                return _vm.rebateBadgeClick(
                                                  item.uuId
                                                )
                                              },
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  486676024
                                ),
                                model: {
                                  value: _vm.tRebates,
                                  callback: function ($$v) {
                                    _vm.tRebates = $$v
                                  },
                                  expression: "tRebates",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isTagVisible
                    ? _c(
                        "b-col",
                        { attrs: { cols: "12" } },
                        [
                          _c(
                            "b-form-group",
                            [
                              _c("TagList", {
                                attrs: {
                                  readOnly: _vm.isTagReadOnly,
                                  tags: _vm.tTags,
                                },
                                on: { modified: _vm.tagsModified },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm.state.skillSelectorShow
        ? _c("GenericSelectorModalForAdmin", {
            attrs: {
              show: _vm.state.skillSelectorShow,
              entityService: _vm.skillUtil,
              entity: "SKILL",
              nonAdmin: "",
            },
            on: {
              "update:show": function ($event) {
                return _vm.$set(_vm.state, "skillSelectorShow", $event)
              },
              ok: _vm.skillSelectorOk,
            },
          })
        : _vm._e(),
      _vm.state.skillLevelEditShow
        ? _c("SkillLevelModal", {
            attrs: {
              show: _vm.state.skillLevelEditShow,
              uuId: _vm.skillLevelEdit.uuId,
              name: _vm.skillLevelEdit.name,
              level: _vm.skillLevelEdit.level,
            },
            on: {
              "update:show": function ($event) {
                return _vm.$set(_vm.state, "skillLevelEditShow", $event)
              },
              ok: _vm.skillLevelOk,
            },
          })
        : _vm._e(),
      _vm._l(_vm.toConfirmSkills, function (item, index) {
        return [
          _c("SkillLevelModal", {
            key: "skill-" + index,
            attrs: {
              show: item.show,
              canApplyAll: _vm.toConfirmSkills.length > 1,
              uuId: item.uuId,
              name: item.name,
              level: item.level,
            },
            on: {
              "update:show": function ($event) {
                return _vm.$set(item, "show", $event)
              },
              ok: _vm.toConfirmSkillOk,
              cancel: _vm.toConfirmSkillCancel,
            },
          }),
        ]
      }),
      _vm.state.staffSelectorShow
        ? _c("StaffSelectorModalForAdmin", {
            attrs: { show: _vm.state.staffSelectorShow, nonAdmin: "" },
            on: {
              "update:show": function ($event) {
                return _vm.$set(_vm.state, "staffSelectorShow", $event)
              },
              ok: _vm.staffSelectorOk,
            },
          })
        : _vm._e(),
      _vm.state.staffUtilizationEditShow
        ? _c("StaffUtilizationModal", {
            attrs: {
              show: _vm.state.staffUtilizationEditShow,
              uuId: _vm.staffUtilizationEdit.uuId,
              name: _vm.staffUtilizationEdit.name,
              utilization: _vm.staffUtilizationEdit.utilization,
            },
            on: {
              "update:show": function ($event) {
                return _vm.$set(_vm.state, "staffUtilizationEditShow", $event)
              },
              ok: _vm.staffUtilizationOk,
            },
          })
        : _vm._e(),
      _vm._l(_vm.toConfirmStaffs, function (item, index) {
        return [
          item.show
            ? _c("StaffUtilizationModal", {
                key: "staff-" + index,
                attrs: {
                  show: item.show,
                  canApplyAll: _vm.toConfirmStaffs.length > 1,
                  uuId: item.uuId,
                  name: item.name,
                  utilization: item.utilization,
                },
                on: {
                  "update:show": function ($event) {
                    return _vm.$set(item, "show", $event)
                  },
                  ok: _vm.toConfirmStaffOk,
                  cancel: _vm.toConfirmStaffCancel,
                },
              })
            : _vm._e(),
        ]
      }),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.$t("project.confirmation.title_apply_rebates"),
            "ok-title": _vm.$t("button.yes"),
            "cancel-title": _vm.$t("button.no"),
            "content-class": "shadow",
            "no-close-on-backdrop": "",
          },
          on: { ok: _vm.applyRebates },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                var cancel = ref.cancel
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "success" },
                      on: { click: _vm.applyRebates },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.yes")))]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.no")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.promptApplyRebates,
            callback: function ($$v) {
              _vm.promptApplyRebates = $$v
            },
            expression: "promptApplyRebates",
          },
        },
        [
          _c("div", { staticClass: "d-block" }, [
            _c("p", [
              _vm._v(_vm._s(_vm.$t("task.confirmation.apply_rebates"))),
            ]),
          ]),
        ]
      ),
      _vm.state.resourceSelectorShow
        ? _c("GenericSelectorModalForAdmin", {
            attrs: {
              show: _vm.state.resourceSelectorShow,
              entityService: _vm.resourceUtil,
              entity: "RESOURCE",
              nonAdmin: "",
            },
            on: {
              "update:show": function ($event) {
                return _vm.$set(_vm.state, "resourceSelectorShow", $event)
              },
              ok: _vm.resourceSelectorOk,
            },
          })
        : _vm._e(),
      _vm.state.resourceUnitEditShow
        ? _c("ResourceUnitModal", {
            attrs: {
              show: _vm.state.resourceUnitEditShow,
              uuId: _vm.resourceUnitEdit.uuId,
              name: _vm.resourceUnitEdit.name,
              unit: _vm.resourceUnitEdit.unit,
              utilization: parseFloat(_vm.resourceUnitEdit.utilization),
            },
            on: {
              "update:show": function ($event) {
                return _vm.$set(_vm.state, "resourceUnitEditShow", $event)
              },
              ok: _vm.resourceUnitOk,
            },
          })
        : _vm._e(),
      _vm._l(_vm.toConfirmResources, function (item, index) {
        return [
          item.show
            ? _c("ResourceUnitModal", {
                key: "resource-" + index,
                attrs: {
                  show: item.show,
                  canApplyAll: _vm.toConfirmResources.length > 1,
                  uuId: item.uuId,
                  name: item.name,
                  unit: item.unit,
                  utilization: parseFloat(item.utilization),
                },
                on: {
                  "update:show": function ($event) {
                    return _vm.$set(item, "show", $event)
                  },
                  ok: _vm.toConfirmResourceOk,
                  cancel: _vm.toConfirmResourceCancel,
                },
              })
            : _vm._e(),
        ]
      }),
      _vm.state.rebateSelectorShow
        ? _c("GenericSelectorModalForAdmin", {
            attrs: {
              show: _vm.state.rebateSelectorShow,
              entityService: _vm.rebateUtil,
              entity: "REBATE",
              nonAdmin: "",
              preselected: _vm.rebateEdit.uuId,
            },
            on: {
              "update:show": function ($event) {
                return _vm.$set(_vm.state, "rebateSelectorShow", $event)
              },
              ok: _vm.rebateSelectorOk,
            },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }